export const openingHours = [
    {
        weekday: "Montag",
        time: "11:30 - 21:00",
        open: true
    },
    {
        weekday: "Dienstag",
        time: "11:30 - 21:00",
        open: true
    },
    {
        weekday: "Mittwoch",
        time: "11:30 - 21:00",
        open: true
    },
    {
        weekday: "Donnerstag",
        time: "11:30 - 21:00",
        open: true
    },
    {
        weekday: "Freitag",
        time: "11:30 - 21:00",
        open: true
    },
    {
        weekday: "Samstag",
        time: "Ruhetag",
        open: false
    },
    {
        weekday: "Sonntag",
        time: "12:00 - 21:00",
        open: true
    },
    {
        weekday: "Feiertage",
        time: "12:00 - 21:00",
        open: true
    }
]