import React, { useState, useEffect } from "react";
import ToggleBtn from "../toggleBtn/ToggleBtn";
import "./noticeBanner.styles.scss";

const NoticeBanner = ({ sessionName = "infoClickedAway", active, title, dangerouslySetInnerHTML, text, date }) => {

    const [sessionEmpty, setSessionEmpty] = useState(false);

    const handleSession = () => {
        window.sessionStorage.setItem(sessionName, true);
        setSessionEmpty(false);
    }

    useEffect(() => {
        if (!window.sessionStorage.getItem(sessionName)) {
            setSessionEmpty(true)
        } else {
            setSessionEmpty(false);
        }
    }, [])

    return (
        <>
            {active && Date.now() < new Date(...date) &&
                <>
                    <div className={`notice-banner__background${!sessionEmpty ? " hide" : ""}`}></div>
                    <div className={`notice-banner${!sessionEmpty ? " hide" : ""}`}>
                        <div className="notice-banner__close">
                            <ToggleBtn toggle={handleSession} isOpen={true} />
                        </div>
                        <div className="notice-banner__container">
                            <div className="notice-banner__title">{title}</div>
                            {text && <div>{text}</div>}
                            {dangerouslySetInnerHTML && <div className="notice-banner__text" dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} />}
                        </div>
                    </div>
                </>}
        </>
    )
}

export default NoticeBanner
