import React from "react";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import "./imageBackground.styles.scss";

const ImageBackground = ({ renderContent, img, imgAlt }) => {
    const image = getImage(img);
    return (
        <BgImage
            image={image}
            className="image-background"
            alt={imgAlt}
        >
            <div className="image-background__filter"></div>
            {renderContent()}
        </BgImage>
    )
}

export default ImageBackground;

