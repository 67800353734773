import { graphql, useStaticQuery } from "gatsby";

const useAnnouncementQuery = () => {

  const data = graphql`
  query AnnouncementQuery {
      allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/announcement/"}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        html
        frontmatter {
          title
          active
        }
      }
    }
  }
  `
  const { allMarkdownRemark } = useStaticQuery(data);

  const announcementData = allMarkdownRemark.nodes.map(({ frontmatter: { title, active }, html }) => { return { title, active, html } })[0]


  return announcementData;
}


export { useAnnouncementQuery };
