import React from "react";
import "./button.styles.scss";


const Button = ({
  btnText = "Button Text",
  negative,
  secondaryColor,
  outline,
  disabled,
  onClick,
}) => {


  return (
    <button aria-label={btnText} disabled={disabled} className={`button${negative ? " negative" : ""}${secondaryColor ? " secondary-color" : ""}${outline ? " outline" : ""}${disabled ? " disabled" : ""}`} onClick={onClick}>
      {btnText}
    </button>
  );
};

export default Button;
