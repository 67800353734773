import React from "react";
import { openingHours } from "../../constants/openingHours";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./openingHours.styles.scss";

export const OpeningHours = () => {
    return (
        <div className="opening-hours__container">
            <div className="opening-hours__icon">
                <AiOutlineClockCircle size={30} />
            </div>
            {
                openingHours.map((day, index) =>
                    <div className="opening-hours__section" key={index}>
                        <strong className="opening-hours__weekdays">
                            {day.weekday}:
                        </strong>
                        <div className="opening-hours__time">
                            {day.time}
                        </div>
                        <div className="opening-hours__suffix">
                            {day.open && "Uhr"}
                        </div>
                    </div>
                )
            }
        </div>
    )
}
